<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Receipt</title>
    <path
      d="M34.2937 34H11.4313V30H34.2937V34ZM34.2937 26H11.4313V22H34.2937V26ZM34.2937 18H11.4313V14H34.2937V18ZM5.71564 44L8.57344 41L11.4313 44L14.2891 41L17.1469 44L20.0047 41L22.8625 44L25.7203 41L28.5781 44L31.4359 41L34.2937 44L37.1515 41L40.0093 44V4L37.1515 7L34.2937 4L31.4359 7L28.5781 4L25.7203 7L22.8625 4L20.0047 7L17.1469 4L14.2891 7L11.4313 4L8.57344 7L5.71564 4V44Z"
      :fill="color"
    />
    <path
      d="M34.2937 34H11.4313V30H34.2937V34ZM34.2937 26H11.4313V22H34.2937V26ZM34.2937 18H11.4313V14H34.2937V18ZM5.71564 44L8.57344 41L11.4313 44L14.2891 41L17.1469 44L20.0047 41L22.8625 44L25.7203 41L28.5781 44L31.4359 41L34.2937 44L37.1515 41L40.0093 44V4L37.1515 7L34.2937 4L31.4359 7L28.5781 4L25.7203 7L22.8625 4L20.0047 7L17.1469 4L14.2891 7L11.4313 4L8.57344 7L5.71564 4V44Z"
      fill="url(#paint0_linear_1720_9387)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1720_9387"
        x1="241.818"
        y1="-157.818"
        x2="4.68956"
        y2="29.7295"
        gradientUnits="userSpaceOnUse"
      >
        <stop :stop-color="color" />
        <stop
          offset="1"
          :stop-color="color"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-receipt',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
    },
  },
}
</script>
